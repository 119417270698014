import React from "react";
// import './Footer.css'

function Footer() {
  return (
    <div className="containerF">
      <div className="btnF">
        <a href="https://discord.gg" className=" pensea">
            
        </a>
        <a href="." className=" iscord"></a>

        <a href="." className=" Xx"></a>
      </div>
      
      <div className="btnFo">
        <a href="." className="Abu">
          
        </a>
        <a href="." className="Don"></a>

        <a href="." className=" QA"></a>
      </div>


      <p className="copywrite">&copy; Hitch2024</p>
    </div>
  );
}

export default Footer;
