import React from 'react'
import './styles.css'
function Chapter1() {
  return (
    <div className="conta1">
     <div className="introP">
          <p>
            Chapter 1 - ?an!c
            <br />
            <br />
            At the end of the centry,
            ?an!c ( Panic ) happened.
            <br />
            <br />
            Ordinary incidents of daily life are
            irreplaceable. They could break anywhere, 
            at any moment.
            <br />
            <br />
            Who does your life belong to ?

            The "Surv!vors" are planning to move
            to space.
            We are starting an adventure-
         
          </p>
        </div>
    </div>
  )
}

export default Chapter1